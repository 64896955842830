import * as React from 'react'
import { useEffect, useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
// COMPONENTS
import { ShadowText } from 'components/styled/typography'
import Layout from 'layouts/main'
import { SCREEN } from 'styles/screens'
import Api from '../helpers/api'
import Loading from '../components/shared/loading'
import { config } from '../config'

// import '../styles/components.css'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 100px 40px 100px;
  // min-height: 800px;
  margin-bottom: 60px;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 16px;
    min-height: 600px;
  }

  .column-title {
    font-weight: 500;
    margin-bottom: 0;
  }
`

const FixedContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1520px;
  width: 100%;
`

const DescriptionContainer = styled.div`
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
  width: 100%;
`

const StyledCard = styled.div`
  &:first-child {
    a {
      border-top: 0;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
  }

  &:last-child {
    a {
      // border-bottom: 0;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }

  a {
    text-decoration: none;
    background-color: #fff;
    border-top: 1px solid #b7b7b7;
    padding: 42px 40px;
    border-radius: 0;
    display: flex;
    flex-direction: row;
    align-items: center;

    .info {
      margin-left: 40px;

      .title,
      .text {
        line-height: 1;
        margin-top: 0;
        margin-bottom: 0;
      }

      .title {
        color: #000;
      }

      .text {
        color: #676767;
        margin-top: 10px;
        margin-bottom: 25px;
      }

      .primary-badge {
        color: #000;
        font-size: 14px;
        padding: 7px 14px;
        background-color: var(--secondary);
        border-radius: 4px;
        margin-right: 10px;
      }
    }

    .time {
      margin-left: auto;
      color: #676767;
      font-size: 18px;
    }

    .mobile-time {
      .time {
        font-size: 14px;
        margin-left: 0;
        margin-right: 4px;
      }

      .primary-badge {
        font-size: 13px;
        padding: 5px;
        margin-right: 10px;
      }

      display: none;
    }
    .avatar img {
      object-fit: cover;
      width: 150px;
      height: 150px;
    }

    @media only screen and (max-width: ${SCREEN.MOBILE}) {
      .info {
        margin-left: 20px;
      }
      padding: 20px 20px;
      .avatar img {
        width: 80px;
        height: 80px;
      }

      .text {
        font-size: 16px;
      }

      .title {
        font-size: 20px;
      }

      .mobile-time {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
        align-items: center;

        .time {
          margin-top: 10px;
          margin-right: 10px;
          display: block;
        }

        .primary-badge {
          margin-top: 10px;
          display: block;
        }
      }

      .time {
        display: none;
      }

      .primary-badge {
        display: none;
      }
    }
  }
`

const Portfolio = () => {
  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    Api.getInstance()
      .get('/products')
      .then(setProducts)
      .catch(console.log)
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return (
    <div className="Homepage">
      <Layout title="Portfolio">
        <Container>
          <FixedContainer>
            <ShadowText highlight>
              <h3>Product’s Portfolio</h3>
            </ShadowText>
            <DescriptionContainer>
              {loading && <Loading />}
              {products.map(product => {
                return (
                  <StyledCard key={`product-${product.id}`}>
                    <a href={`/product/${product.key}`}>
                      <div className="avatar">
                        <img
                          src={`${config.CDN_BASE_URL}/${product.logo}`}
                          alt="workspace"
                          layout="constrained"
                          placeholder="tracedSVG"
                        />
                      </div>
                      <div className="info">
                        <h6 className="title">{product.name}</h6>
                        <div className="mobile-time">
                          <div className="time">{product.batch}</div>
                          {product.tags.split(',').map(tag => {
                            return <span className="primary-badge">{tag}</span>
                          })}
                        </div>
                        <p className="text">{product.slogan}</p>
                        {product.tags.split(',').map(tag => {
                          return <span className="primary-badge">{tag}</span>
                        })}
                      </div>
                      <div className="time">{product.batch}</div>
                    </a>
                  </StyledCard>
                )
              })}
            </DescriptionContainer>
          </FixedContainer>
        </Container>
      </Layout>
    </div>
  )
}

export default Portfolio
